.holder {
  display: flex;
  height: 100vh;
}

.loader-bg {
  background-color: #fff !important;
  width: 100vw !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-width: 100% !important;
}

.holder img {
  width: 48px;
  margin: auto;
}

.holder .loading {
  border: 0px solid #0052cc;
  width: 96px;
  height: 96px;
  margin: auto;
  display: flex;
  border-radius: 50%;
  animation: home-loader 1.5s infinite;
}

@keyframes home-loader {
  0% {
    border-width: 0px;
    border-color: #0052cc4a;
  }
  50% {
    border-width: 8px;
    border-color: #0052cc94;
  }
  100% {
    border-width: 0px;
    border-color: #0052cc4a;
  }
}
