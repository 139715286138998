.rowContainer {
    height: 100vh;
  }
  
  .leftside {
    background-color: #69bec6;
  }
  
  .button1 {
    background-color: #30346b;
    font-size: 15px;
    text-transform: none;
    color: "#ffff";
    border-radius: 5px;
  }
  
  .button1:hover {
    color: #2b339d;
  }
  
  .input {
    width: 90%;
    padding: 2%;
  }
  
  .form-control {
    background-color: #e5e5e5;
    padding: 3%;
  }
  
  .subtext {
    color: #858585;
    font-size: medium;
  }
  