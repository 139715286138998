#document-wrapper {
    display: flex;
    flex-direction: column;
}

#document-wrapper img {
    user-select: none;
}

#document-wrapper .back-button,
#document-wrapper .review-button {
    display: flex;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 8px 16px;
    border-radius: 0px;
    color: #ffffff;
    cursor: pointer;
}

#document-wrapper .submit-button {
    display: flex;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    /* margin: 0; */
    /* padding: 8px 16px; */
    border-radius: 0px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 75px;
    padding-left: 123px;
    padding-bottom: 11px;
    padding-top: 13px;
}
#document-wrapper .review-button {
    background-color: #ffc107;
    color: #5e5584;
}

#document-wrapper .submit-button {
    background-color: #28a745;
}

#document-wrapper .submit-button:hover {
    background-color: #28a745;
    background-color: #289645;
}

#document-wrapper a {
    text-decoration: none;
}

#document-wrapper p {
    margin: 0;
}

#document-wrapper .back-button:hover {
    background-color: #333333;
}

#document-wrapper .document-topbar {
    height: 40px;
    background-color: #0052cc;
    text-align: left;
    color: #ffffff;
    box-shadow: -1px 0px 8px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
}

.document-container {
    height: calc(100vh - 40px);
    display: flex;
    flex-grow: 1;
}

.document-name {
    font-size: 16px;
    font-weight: 600;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.no-pending-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

#comment-box {
    font-family: Montserrat;
}
/* a.stretched-link::after {
  cursor: context-menu !important;
} */
.stretched-link {
    text-decoration: none;
    /* cursor: context-menu !important; */
}

#topbar-wrapper {
    display: flex;
    flex-direction: column;
}

#topbar-wrapper .back-button {
    display: flex;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 8px 16px;
    border-radius: 0px;
    color: #ffffff;
    cursor: pointer;
    width: 200px;
}

#topbar-wrapper .document-topbar {
    height: 40px;
    background-color: #0052cc;
    text-align: left;
    color: #ffffff;
    box-shadow: -1px 0px 8px 0 rgba(0, 0, 0, 0.4);
    display: flex;
}

#topbar-wrapper .back-button:hover {
    background-color: #333333;
}
.MuiDataGrid-root.MuiDataGrid-root {
    height: 85vh !important;
    font-family: Montserrat !important;
}
.MuiDataGrid-root .MuiDataGrid-row {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    margin: 4px 0px;
}

.overflow-auto {
    overflow: auto !important;
    margin: 0.5rem;
    max-height: 40vh;
}

.MuiDataGrid-viewport {
    /* min-width: 1349px !important;
  max-width: 1349px !important;
  min-height: 750px !important;
  max-height: 1080px !important; */
}
/* .MuiDataGrid-dataContainer.data-container {
  min-height: 100% !important;
}
.rendering-zone {
  transform: none !important;
}
.MuiDataGrid-row.Mui-odd {
  min-height: 66px !important;
  max-height: 66px !important;
}
.MuiDataGrid-cell {
  min-height: 66px !important;
  max-height: 66px !important;
  line-height: 66px !important;
}
.MuiDataGrid-row.Mui-even {
  min-height: 66px !important;
  max-height: 66px !important;
} */
.MuiDataGrid-root .MuiDataGrid-toolbar {
    flex-direction: row-reverse !important;
}
.MuiDataGrid-colCellTitleContainer {
    text-align: center;
}
.MuiDataGrid-colCellTitle {
    width: 100%;
}
.MuiDataGrid-columnsContainer {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
.MuiDataGrid-footer {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}
#topbar-wrapper .topbar-text {
    flex-grow: 1;
    text-align: center;
    margin: auto;
    font-size: 1rem;
}

#review-container {
    border: 1px solid #e7e7e7;
    padding: 1.5rem;
    margin: 1.5rem;
    border-radius: 15px;
}

#review-container .or-text {
    text-align: center;
    margin: 2% 0;
    color: grey;
}

#review-container .document-list {
    width: fit-content;
    margin: auto;
}

#review-container .card-body {
    padding: 1rem;
}

#review-container .document-card {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.22;
    text-align: center;
    margin: 8px 0px;
    border-width: 0px;
}

#review-container .document-list-item:hover {
    background-color: #f5f5f5;
}

#review-container .document-header {
    border-radius: 3px;
    box-shadow: none;
    font-weight: 600;
}

#review-container .fields-container {
    display: flex;
}

#review-container .document-card span,
#review-container.document-card [class^='field-'] {
    padding: 4px;
    margin: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#review-container .document-header .edit-icon:hover {
    background-color: #c5c5c569;
}

#review-container .document-card .edit-icon {
    margin: 0;
    padding-right: 7px;
    width: 48px;
    cursor: pointer;
    color: #0052cc;
}

#review-container .document-card .move-checkbox {
    padding-right: 20px;
    margin: 0;
}

#review-container .document-card .field-1 {
    width: 60px;
}

#review-container .document-card .field-2 {
    width: 250px;
}

#review-container .document-card .field-3 {
    width: 200px;
}

#review-container .document-card .field-4 {
    width: 500px;
}

#review-container .document-card .field-5 {
    width: 200px;
}
#review-container .document-card .field-6 {
    width: 32%;
}
.pending-button {
    display: flex;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 8px 16px;
    border-radius: 0px;
    color: #ffffff;
    cursor: pointer;
    background-color: #28a745;
}

#document-wrapper .pending-button:hover {
    background-color: #289645;
}

.review-documents {
    max-height: 30rem;
    overflow: auto;
}
