.modal_body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.mv_pending__cot {
  height: fit-content;
  background-color: #fff;
  /* border-radius: 8px; */
  /* border: 1px solid #30346b; */
  padding: 12px 24px;
  margin: 1rem;
}

.p1 {
  height: 3.5rem !important;
}

.createButton {
  background-color: #69bec6;
  border: none;
}

.createButton:hover {
  background-color: #46adb6;
}

.form_element_ip {
  width: 80%;
  margin: 15px auto;
}
