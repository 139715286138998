#fields-container {
  width: 93%;
  background-color: #ffffff;
  padding: 12px;
  text-align: left;
  color: #0052cc;
  border-top: 2px solid white;
  margin-left: 0rem;
}

#fields-container .field {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  background-color: #f4f5f7;
  box-shadow: none;
  font-size: 1em;
  margin-left: 1px !important;
  border-color: #E7EBF0;
}

#fields-container .field:focus {
  border: solid 2px #0052cc;
}

#fields-container .field-error {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  background-color: #f4f5f7;
  border: solid 1px #ff0000bf;
  box-shadow: none;
  font-size: 1em;
}

#fields-container .field-error:focus {
  box-shadow: 1px 1px 4px 1px #ff0000bf;
}

.section-tab {
  display: flex;
  margin: 0.75rem 0 0.25rem;
  flex-grow: 1;
  padding: 0 0.75rem;
  cursor: pointer;
}

.drawer {
  width: fit-content;
  padding: 3rem;
}

.add-icon {
  margin: 2px auto;
  font-size: 30px !important;
  color: #0052cc;
}

.tdata {
  border: none;
  padding: 0.25rem !important;
}

.button1 {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #0052cc;
  color: #f0f8ff;
  text-align: center;
  font-size: larger;
  font-weight: 700;
  padding: 6px !important;
}


