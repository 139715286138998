.form_container_cp {
  width: 720px;
  display: flex;
  flex-direction: column;
}

.form_element_cp {
  width: 80%;
  margin: 24px auto;
}

.check_cont {
  display: flex;
  margin-bottom: 12px;
  gap: 124px;
  /* margin-top: 12px; */
}

.tags_list_cl {
  width: 100%;
  min-height: 64px;
  background-color: rgb(250, 248, 248);
  margin-left: 8px;
  margin-top: 24px;
  border: 1px solid rgb(218, 215, 215);
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag_elem_cl {
  height: 32px;
  background-color: #3f51b5;
  width: max-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-evenly;
  padding-inline: 4px;
}

.tag_elem_close_cl {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #96a3eb;
  cursor: pointer;
}

.or_line {
  color: rgb(155, 152, 152);
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.upload_cont {
  border: 1px solid black;
  border-radius: 8px;
  width: 40%;
  margin: 12px auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.section_list_cl {
  width: 100%;
  min-height: 64px;
  background-color: rgb(250, 248, 248);
  margin-left: 8px;
  margin-top: 24px;
  border: 1px solid rgb(218, 215, 215);
}

.section_head_cl {
  background-color: #46adb6;
  height: 36px;
  width: 100%;
  font-size: 1.3rem;
  color: #fff;
  display: flex;
  padding-inline: 16px;
  justify-content: space-between;
  align-items: center;
}

.section_elem_cl {
  height: 36px;
  width: 100%;
  font-size: 1.1rem;
  display: flex;
  padding-inline: 16px;
  justify-content: space-between;
  align-items: center;
}

.section_tb_actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;
}

.table_dropdown_images {
  cursor: pointer;
}

.users_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  gap: 4px;
  row-gap: 12px;
}

.userItem {
  display: flex;
  justify-content: flex-start;
  width: 33%;
  gap: 8px;
}

.userItem label {
  cursor: pointer;
  user-select: none;
}


/* Demo styles to better view border radius */
.rounded {
  border: 1px solid #777;
  padding: 10px;
  font-size: 14px;
  color: -internal-light-dark(#777, white);
}
.search {
  display: block;
    width: 50%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
h5{
  padding:1rem;
}
