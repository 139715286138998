.rowContainer {
  height: 100vh;
}

.row{
  display: flex;
}

.leftside {
  background-color: #69bec6;
  /* grid-column: 1;
    grid-row: 1; */
}

.button1 {
  background-color: #69bec6;
  font-size: 15px;
  text-transform: none;
  color: "#ffff";
  border-radius: 5px;
}

.button1:hover {
  color: #2b339d;
}

.input {
  width: 90%;
  padding: 2%;
}

.form-control {
  background-color: #e5e5e5;
  padding: 3%;
}

.subtext {
  color: #858585;
  font-size: medium;
}
