.btn {
    max-height: 3.25rem;
    min-width: 11rem;
}
label {
    margin-bottom: 0.5rem;
}
input,
.Dropdown-control {
    max-height: 3.25rem !important;
    margin-left: 8px !important;
    /* border: #c9c8c8 !important; */
    border-radius: 2px !important;
}

.modal input {
    height: 42px !important;
}
.table {
    width: calc(100vw - 6rem - 12rem);
    overflow: scroll;
}

th,
td {
    height: 1.5rem;
    vertical-align: middle;
    text-align: center;
    
}
.header2 th,td {
    height: 2.8rem !important;
    vertical-align: middle;
    text-align: center;
    
    
}
.header3{
    width: calc(100vw - 6rem - 15rem);
    overflow: scroll;
  }

.active {
    color: green !important;
    font-weight: bold !important;
}

.actions {
    height: 1rem;
    cursor: pointer;
}

.table-wrapper {
    position: relative;
    height: calc(100vh - 14rem);
    overflow: auto;
}


.doc-table-scroll::-webkit-scrollbar {
    display: block;
    width: 10px;
    height: 0px;
}

.doc-table-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}
    
.doc-table-scroll::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-right: none;
    border-left: none;
}

.doc-table-scroll::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px; 
}

.doc-table-scroll::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: calc(100vh - 88.8vh);
}

thead th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #ffffff ;
    background-attachment: fixed;
}
thead tr {
    border-bottom: 2px solid currentColor;
}

.info {
    height: 1rem;
    margin-left: 8px;
    margin-bottom: 4px;
}

.form-group {
    margin: auto;
    margin-top: 10px;
}

.pointer {
    cursor: pointer;
}

.multiSelect {
    width: 30rem;
    margin-left: 2rem;
}

.multiSelect > div:first-child {
    height: 3rem;
}

.searchWrapper {
    min-height: 3rem !important;
    margin-left: 1rem;
}

.multiSelectContainer {
    z-index: 10000;
}

.createModal .form-group {
    width: 100%;
}

/* button */

.btn-custom-primary {
    background-color: #2b339d;
    font-size: 15px;
    color: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.add-icn {
    font-weight: 400;
    font-size: 1.52rem;
}

.form-control {
    background-color: unset !important;
}

.form-group {
    max-width: 20rem;
}

/* Modal */

.modal-dialog {
    min-width: 53rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.modal-title {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.modal-body {
    padding-bottom: 2rem;
    max-height: 70vh;
    overflow: scroll;
}

.modal-content {
    border-radius: 1rem;
}

.divider::before {
    content: '';
    height: 3px;
    width: 5rem;
    border-bottom: solid 1px #000;
    position: absolute;
    top: 41%;
    right: 34px;
}

.divider::after {
    content: '';
    height: 3px;
    width: 5rem;
    border-bottom: solid 1px #000;
    position: absolute;
    top: 41%;
    left: 34px;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
    margin-right: 4px;
}

.badge-pill {
    padding: 0.7rem;
    border-radius: 10rem;
}

h3 {
    font-size: 26px;
    font-weight: medium;
    color: #3f4254;
    line-height: 40px;
}

hr {
    height: 0.25px;
    color: #c9c8c8;
}

.hed-row {
    /* padding-left: 7.5rem !important; */
    margin-bottom: 0 !important;
    align-items: center;
}

.custom-dropdown {
    min-width: 13rem !important;
}

.hed-row input {
    max-width: 9.5rem;
    max-height: 2.75rem !important;
}

/* .hed-row button {
    max-height: 2.75rem !important;
    background-color: #2b339d;
    font-size: 15px;
    color: #ffffff;
    min-width: 8rem !important;
} */

.badge {
    font-size: 1rem !important;
}

.actions_content {
    width: max-content;
    min-width: 120px;
    position: absolute;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 6px;
    left: -40%;
    top: 64px;
    text-align: left;
    z-index: 1000;
}

.actions_item {
    cursor: pointer;
    padding: 4px 8px;
    border: 0.3px solid black;
    /* border-bottom: 1px solid black; */
}

.actions_item:hover {
    background-color: aliceblue;
}
.error {
    padding: 1rem 1.25rem;
    color: #f65157;
}

.table_inputs {
    height: 42px;
    background-color: #fff;
    width: 12.4rem;
    border: 1px solid #cccccc;
    padding: 12px 12px;
}

/* .header{
  width:1px;
  padding-left:50px
} */

h5 {
    padding-left: 20%;
    font-family: Arial, Helvetica, sans-serif;
}
.mw-30 {
    max-width: 30rem !important;
}

.flex {
    display: flex;
}

.justify-end {
    justify-content: flex-end;
}
