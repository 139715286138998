.doc_verify_cont {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header_cont_vc {
  min-height: 50px;
  width: 100vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font-size: 1.4rem;
}

.body_cont_vc {
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.list_bar {
  width: 15rem;
  height: calc(100vh - 50px);
  background-color: #f0f0f0;
  transition: width ease-in-out 0.4s;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.minimize_list_bar {
  width: 10%;
  height: calc(100vh - 50px);
  background-color: #f0f0f0;
  transition: width ease-in-out 0.4s;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

/* .list_bar:hover {
  width: 320px;
} */

.image_bar {
  flex: 1 1 0;
  overflow-y: auto;
}

.image_canvas {
  max-height: 400px;
}

.form_bar {
  width: 305px;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  border-left: 1px solid #ddd;
}

.minimize_form_bar {
  width: 305px;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
  border-left: 1px solid #ddd;
}

.secondaryButton {
  width: 10px;
}

.home_btn_vc {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin-left: 5rem;
}

.home_btn_vc:hover {
  fill: #5668b6;
}

.doc_name_vc {
  width: fit-content;
}

.doc_type_vc {
  margin-left: auto;
  margin-right: 8px;
  width: fit-content;
}

.review_btn_vc {
  background-color: #ffc107;
  color: #21368f;
  font-weight: 600;
  width: max-content;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
}

.number_elems {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2px;
  gap: 12px;
  margin-top: 2px;
}

.sb_img_doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
  cursor: pointer;
}

.minimize_sb_img_doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  gap: 24px;
  cursor: pointer;
}

.forPageDisplaying {
  padding: 5px;
  padding-bottom: 13px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.confirmPageText {
  background-color: green;
  width: "10%";
  min-height: "10%";
  display: "flex";
  justify-content: "end";
  margin-right: "-10%";
}

.pageSelectionHighlight {
  background-color: lightblue;
  border: 1px solid;
  border-color: #eee;
  /* box-shadow: 0 20px 20px rgba(206, 233, 255, 0.5); */
}

.pageNotSelectionMode {
  border: 2px solid;
  border-color: #dedede;
}

.confirmPage {
  background-color: green;
}

.caseLabel {
  font-size: 70px;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: gray;
}

.number_keys {
  width: 20px;
  height: 20px;
  /* border-radius: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #21368f; */
}

.number_keys > * {
  position: relative;
  bottom: 8px;
}

.sidebar_images_vc {
  width: 90%;
}

.selected_keys {
  background-color: darkgreen;
  color: #fff;
  border-radius: 30px;
  border: 2px solid darkgreen;
  /* border-radius: 10px; */
}

.review__cot {
  height: fit-content;
  width: 420px;
  background-color: #fff;
  position: absolute;
  left: 60rem;
  top: 0rem;
  z-index: 1000;
  border-bottom-left-radius: 8px;
  border: 1px solid #30346b;
  padding: 12px 24px;
}

.cursorPointer {
  cursor: pointer;
}

.iconScale {
  transform: scale(1.8);
}

.showIcon {
  color: #aaa;
}

.meta-data::after {
  position: absolute;
  width: 15px;
  height: 15px;
  content: "";
  top: 8px;
  right: -5px;
  z-index: 0;
  transform: rotate(35deg);
  background-color: #6c757d;
}
