.main_sidebar {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 4.5rem;
  height: 100vh;
  background-color: #fff;
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  border-right: 1px solid #E7EBF0
}

.logo {
  width: 36px;
  filter: invert(0%);
  cursor: pointer;
  margin-bottom: 4px;
}

.sidebar_user {
  position: relative;
  text-align: center;
}

.user_card {
  position: absolute;
  z-index: 100000;
  width: 190px;
  height: 220px;
  border-radius: 20px;
  top: 0;
  left: 78px;
  border: 3px solid#21368f;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;
}

.circle_name {
  border: 3px solid #21368f;
  border-radius: 50px;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_name_text {
  color: #21368f;
  font-size: 3rem;
  position: relative;
  /* bottom: 4px; */
}

.hrule {
  height: 2px;
  width: 100%;
  background-color: #fff;
  margin: 12px 0;
}

.sidebar_elements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 6rem;
}

.sdr_item {
  width: 100%;
  height: 64px;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: invert(1); */
  /* filter:  brightness(0) invert(1); */
}

.profile {
  width: 100%;
  height: 64px;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: invert(1); */
  /* filter:  brightness(0) invert(1); */
}

.profile:hover {
  cursor: pointer;
  /* filter: brightness(0) invert(1); */
  transform: scale(120%);
  /*background-color: #ffffff;
  */
  /* filter: invert(0); */
  box-sizing: border-box;
  /* -webkit-filter: brightness(0) invert(1); */
  color:#46adb6
}

.sdr_item:hover {
  cursor: pointer;
  /* filter: brightness(0) invert(1); */
  transform: scale(120%);
  /*background-color: #ffffff;
  */
  /* filter: invert(0); */
  box-sizing: border-box;
  /* -webkit-filter: brightness(0) invert(1); */
  color:#46adb6
}

.sdr_active {
  /* filter: brightness(0) invert(1); */
  color: #69bec6;
}

.sdr_active > * {
  transform: scale(100%) !important;
}

.sdr_item:hover > * {
  transform: scale(120%);
  fill:'#46adb6'
}

.logout_element {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout_element:hover {
  transform: scale(110%);
  filter: brightness(0) invert(1);
}

.logout_element > * {
  width: 30px;
  cursor: pointer;
}

/* Header */

.header {
  height: 3.5rem;
  width: 100vw;
  /* position: fixed; */
  top: 0;
  background: white;
  z-index: 1000;
  background-attachment: fixed;

}

.iconBox{
  margin-right: 13px;
}

.icon{
  color:#aaa;
}


.header p {
  margin-bottom: 0;
}
/* Badge */

.badge-primary {
  padding: 0.5rem;
  background: #2b339d;
  margin-left: 0.5rem;
  margin-right: 2rem;
}

.header-name {
  font-size: 22px;
  margin-right: 5px;
}
