.spinner-wrapper {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: .95em auto 0
}

.spinnerOutlined-wrapper{
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: auto
}

#spinner {
    width: 20px;
    height: 20px;
    border: 4px solid lightgray;
    border-radius: 50%;
    border-top: 4px solid #30346B;
    margin-right: 10px;
    animation: spin 1.3s linear infinite;
}

.loading-text {
    color: #070c5a;
}

@keyframes spin {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}