.primary-button {
  background-color: #69bec6 !important;
  border-color: #69bec6 !important;
}

.primary-button:hover {
  background-color: #46adb6 !important;
  border-color: #46adb6 !important;
}

.primary-button:focus {
  background-color: #46adb6 !important;
  border-color: #46adb6 !important;
  border: none !important;
}

.primary-button:disabled {
  background-color: #cccc !important;
  border-color: #ccc !important;
}
