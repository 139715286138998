#draggable {
  background: rgb(0, 82, 204);
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  cursor: col-resize;
  height: 20px;
}

#image-container {
  /* overflow: scroll; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#image-container .image {
  width: 700px;
}

#image-container .button1 {
  width: 96px;
  height: 40px;
  border-radius: 4px;
  background-color: #0052cc;
}

#classification-type-text {
  font-size: large;
  font-family: "Montserrat";
  margin-right: 0.5rem;
}

#image-container .button1:hover,
#image-container .button1:focus,
#image-container .button1:active {
  background-color: #0052a0;
}

#image-container .nav-btn-group {
  display: flex;
}

#image-container .table-div {
  max-height: 40vh;
  overflow: auto;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: all 0.5s;
}

#image-container .image-canvas {
  max-height: 85vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  margin-top: 0.2em;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: all 0.5s;
}

#image-container .zoom-canvas {
  max-height: 85vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  margin-top: 0.2em;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: all 0.5s;
}

.minimiseToolbarposition {
  left: 19%
}

.iconColor {
  color: "white"
}

#image-container .image-canvas-container {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  max-height: 90vh;
  overflow-y: auto;
  transition: all 0.5s;
  /* max-width: 70vw; */
  width: 100%;
  margin: 1em;
}

#image-container .icon {
  width: 1.3em;
  height: 1.3em;
  color: #0052cc;
}

#image-container .info-icon {
  float: right;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  color: #0052cc;
}

.form-check-input {
  border-radius: 50px;
}

#boundingbox-switch {
  margin-right: 0.5rem;
  border-radius: 50px !important;
}

#submit-button {
  padding-top: 1rem !important;
  height: 45px !important;
}

#fields-container .form-control {
  padding: unset !important;
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}